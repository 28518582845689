body{
  font-weight: bold;
  width: 100vw;
  height: 100vh;
  font-size: 42px;
  text-align: center;
}

#page{
  background-color: #0974f6;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#main{
  color: #e9e9e9;
}
.cheese{
  background-color: #151515;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.cheese img{
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80vw;
  max-height: 80vh;
  min-width: 40vw;
}
#start_button{
  display: flex;
  width: 200px;
  height: 100px;
  font-size: 42px;
  align-self: center;
  justify-self: center;
  align-items: center;
  justify-content: center;
  color: #222;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  background-color: #e9e9e9;
  padding: 15px 30px;
}
.center-this-pls{
  background-color: #0974f6;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 992px) {
  body {
    font-size:18px;
  }
}